<template>
    <section id="users-list-page">
      <v-col md="10" xs="12">
        <v-card>
          <v-card-title>
            <div class="text-capitalize">
              {{$t('navigation.messages')}}
            </div>
            <v-btn class="ml-auto hidden-xs-only" color="primary" @click="createMessage">
              Add Message
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-sheet>
              <messages-table-filters />
              <messages-table />
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>
      <message-create-popup/>
      <message-view-popup />
    </section>
  </template>
  
  <script>
  import MessagesTable from "../../../../messages/list/components/MessagesTable";
  import MessagesTableFilters from "../../../../messages/list/components/MessagesTableFilters";
  import MessageCreatePopup from "../../../../messages/create/MessageCreatePopup";
  import MessageViewPopup from "../../../../messages/show/MessageViewPopup.vue";
  import {EventBus} from "../../../../../../bootstrap/EventBus";
  
  export default {
    name: "UsersCaretakerMessages",
    components: {MessagesTable, MessagesTableFilters, MessageCreatePopup, MessageViewPopup},
    methods: {
      createMessage() {
        EventBus.$emit('message-create-popup')
      }
    }
  }
  </script>